import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { mapToTheme } from 'styled-map'

import { Text, Flex, Box, Icon } from 'Components/UI'
import { longArrowRightGlyph } from 'Assets/Svg'

export const Container = styled(Box).attrs({})`
  cursor: pointer;
  position: relative;
`

export const ContentHolderWrapper = styled(Box).attrs(({ landscape }) => ({
  id: 'cardContentHolderWrapper',
  p: landscape ? 0 : '12px',
}))`
  width: 100%;
  height: 100%;
  border-radius: ${themeGet('borderRadius.1')};
  overflow: hidden;
  border: 1px solid ${themeGet('colors.lightGrey')};
  background-color: ${themeGet('colors.white')};

  transform: none;
  transition:
    all 0.2s ease 0s,
    -webkit-transform 0.2s ease 0s;

  @media (hover: hover) {
    &:hover {
      transform: ${({ landscape }) => (landscape ? 'none' : 'scale(1.02)')};
      box-shadow: ${({ landscape }) =>
        landscape ? 'none' : 'rgba(0, 0, 0, 0.15) 0px 2px 9px 2px'};
    }
  }
`

export const ContentHolder = styled(Flex).attrs({
  id: 'cardContentHolder',
})`
  background-color: ${themeGet('colors.white')};
  width: 100%;
  min-height: 110px;
  position: relative;
  gap: 10px;

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    height: unset;
  }
`

export const ImageHolder = styled(Box).attrs(({ landscape }) => ({
  height: landscape ? '110px' : 'unset',
}))`
  min-width: ${({ landscape }) => (landscape ? '215px' : '227px')};
  position: relative;

  @media screen and (max-width: ${themeGet('breakpoints.2')}) {
    min-width: ${({ landscape }) => (landscape ? '125px' : '215px')};
  }

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    height: ${({ landscape }) => (landscape ? '110px' : 'unset')};
  }
`

export const ImageBg = styled(Box).attrs(({ src }) => ({
  is: 'img',
  src: src || 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=',
}))`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: ${({ landscape }) => (landscape ? `4px 0 0 4px` : '4px')};
`

export const Overlay = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
  display: ['none', 'none', 'flex'],
  id: 'overlayShowOnMap',
})`
  background-color: ${themeGet('colors.blackOpacity')};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  height: 26px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  z-index: 1;
`

export const ArrowIcon = styled(Icon).attrs({
  glyph: longArrowRightGlyph,
  size: 16,
  ml: 2,
})`
  fill: ${themeGet('colors.white')};
`

export const Show = styled(Box)`
  color: ${themeGet('colors.white')};
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
`

export const MainContentHolder = styled(Flex).attrs({
  flexDirection: 'column',
})`
  flex: 1 1 auto;
  min-width: 0;
  position: relative;
  gap: ${({ gap }) => gap};

  .description-container {
    background-color: white;
    padding: 0;

    * {
      color: ${themeGet('colors.text')} !important;
      font-size: 12px !important;
    }
  }
`

export const HeaderContentHolder = styled(Flex).attrs({
  justifyContent: 'space-between',
})`
  gap: ${({ gap }) => gap};
`

export const TitleHolder = styled(Flex).attrs({
  flex: 1,
  flexDirection: 'column',
})`
  gap: ${({ gap }) => gap};
`

export const BottomContentHolder = styled(Flex).attrs({
  flex: 1,
  justifyContent: 'space-between',
})`
  gap: 8px;
`

export const Content = styled(Flex).attrs({
  flexDirection: 'column',
  flex: 1,
})`
  gap: 8px;
  min-width: 0;
  width: 100%;
`

export const Title = styled(Text).attrs({})`
  font-weight: bold;
  line-height: unset;
  letter-spacing: 0.6px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${({ lines }) => lines};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  width: 100%;
`

export const Location = styled(Flex).attrs({
  justifyContent: 'flex-start',
  alignItems: 'center',
})`
  flex-wrap: nowrap;
  white-space: nowrap;
`

export const LocationText = styled(Text).attrs({
  fontSize: 14,
})`
  color: ${themeGet('colors.text')};
  letter-spacing: 0.4px;
`

export const Business = styled(Flex).attrs({
  justifyContent: 'flex-start',
  alignItems: 'center',
})`
  flex-wrap: nowrap;
`

export const BusinessName = styled(Text).attrs({})`
  color: ${themeGet('colors.text')};
  letter-spacing: 0.4px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  width: 100%;
`

export const LocationIcon = styled(Icon).attrs({
  size: 15,
  mr: 1,
})`
  color: ${props =>
    props.selected
      ? themeGet('colors.white')
      : props.color || themeGet('colors.tealBlue')};
`

export const LabelsContainer = styled(Flex).attrs({
  alignItems: 'center',
})`
  gap: 10px;
`

export const Duration = styled(Flex).attrs({
  alignItems: 'center',
})`
  color: ${themeGet('colors.text')};
  user-select: none;
  font-size: ${({ fontSize }) => fontSize}px;
`

export const DurationIcon = styled(Icon).attrs({
  size: 14,
  mr: 1,
})`
  stroke: ${props =>
    props.selected
      ? themeGet('colors.white')
      : props.color || themeGet('colors.tealBlue')};
`

export const PriceHolder = styled(Flex).attrs({})`
  gap: 4px;
`

export const Price = styled(Flex).attrs({
  alignItems: 'flex-end',
})`
  grid-column-gap: 4px;
  font-weight: bold;
  letter-spacing: 0.44px;
  color: ${({ color }) => color || mapToTheme('colors.font.primary')};
  font-size: ${({ fontSize }) => fontSize};
`

export const PriceLabel = styled(Flex).attrs({
  alignItems: 'center',
})`
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.44px;
  color: ${themeGet('colors.text')};
  white-space: nowrap;
`

export const PriceCross = styled(Flex).attrs({})`
  color: ${themeGet('colors.text')};
  font-weight: normal;
  text-decoration: line-through;
`

export const From = styled(Box).attrs({
  display: 'block',
})`
  :after {
    color: ${themeGet('colors.text')};
    content: 'From';
    font-size: 12px;
    font-weight: normal;
  }
`
const invertLabelCss = ({ invert, bgcolor }) =>
  invert &&
  css`
    color: ${bgcolor || mapToTheme('buttons.bg')};
    background-color: transparent;
    border: 1px solid ${bgcolor || mapToTheme('buttons.bg')};
    font-weight: normal;
  `

export const Label = styled(Box).attrs({
  fontSize: '11px',
  display: 'inline-block',
})`
  padding: 0px 8px;
  line-height: unset;
  width: fit-content;
  white-space: nowrap;
  border-radius: ${themeGet('borderRadius.2')};
  letter-spacing: 0.4px;
  font-weight: 600;
  user-select: none;

  color: ${({ color }) => color || mapToTheme('colors.white')};
  background: ${({ bgcolor }) => bgcolor || mapToTheme('buttons.bg')};
  border: 1px solid ${({ bgcolor }) => bgcolor || mapToTheme('buttons.bg')};

  ${invertLabelCss}
`

export const DescriptionContainer = styled(Flex).attrs({
  flexDirection: 'column',
})`
  gap: 10px;
  background: #f7f7f7;
  padding: 14px;

  p:not(:last-child) {
    margin-bottom: 12px !important;
  }
`

export const Description = styled.div`
  font-size: 14px;
`

export const LinkButton = styled(Flex).attrs({})`
  cursor: pointer;
  white-space: nowrap;
  text-decoration: underline;
  font-size: 14px;
`
